<template>
    <!-- Sub Nav loading -->
    <div class="right-sub-nav fixed">
        <div class="nav-row">
            <div class="sub-nav-left">
                <div class="lines shine"></div>
                <div class="lines shine"></div>
                <div class="lines shine"></div>
            </div>
            <div class="sub-nav-right">
                <div class="lines shine"></div>
                <div class="lines shine"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubNavLoading",
};
</script>

<style lang="scss" scoped>
.lines {
    height: 1.2em;
    width: 100px;
    border-radius: 6px;
}
</style>